import React, { useEffect, useCallback, useState } from 'react';
import PropTyeps from 'prop-types';
import { useHistory } from 'react-router-dom';
import BasicModal from '../../components/BasicModal';
import AuthApi from '../../../api-clients/AuthApi';
import { validateEmoji, validateEmail } from '../../utils/validators';
import LoadingIndicator from '../../components/LoadingIndicator';
import { ErrorAlert, SuccessAlert } from '../../components/common';
import { FormattedMessage, useIntl } from 'react-intl';

function useHash(hash) {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const onHashChange = useCallback(() => {
    if (window.location.hash === hash) {
      setIsOpen(true);
    }
  }, []);

  function close() {
    setIsOpen(false);
    history.push('/', {
      hash: '',
    });
  }

  useEffect(() => {
    if (window.location.hash) {
      onHashChange();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange, false);
    return () => {
      window.removeEventListener('hashchange', onHashChange, false);
    };
  });

  return { isOpen, close };
}

function ResetPasswordModal({ authApi, hash }) {
  const intl = useIntl();
  const { isOpen, close } = useHash(hash);
  const [email, setEmail] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [resetError, setResetError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  function onEmailChange(evt) {
    setEmail(evt.target.value);
  }

  async function onSubmit() {
    setResetError('');
    setIsProcessing(true);
    try {
      await authApi.resetPassword({ email });
      setIsSuccessful(true);
      setIsProcessing(false);
    } catch (error) {
      setResetError(error.message);
      setIsProcessing(false);
    }
  }

  const isEmailValid = !validateEmoji(email) && email.length > 2 && validateEmail(email);

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={close}
      modalKey="reset-password-modal"
      dialogClassName="modal-dialog-centered"
    >
      <div className="modal-body p-3 p-md-4">
        {!isSuccessful && resetError === '' && (
          <>
            <h4 className="text-center mb-3 mt-3 title--auth-modal text-uppercase">
              <FormattedMessage defaultMessage="Forgot your password?" />
            </h4>
            <p className="text-center mt-5">
              <FormattedMessage defaultMessage="We will send you an email to reset your password." />
            </p>
            <div className="material-ui-input-group mb-4">
              <input
                value={email}
                onChange={onEmailChange}
                placeholder={intl.formatMessage({ defaultMessage: 'E-mail' })}
                className="rounded-0 outline-0"
                name="email"
                type="text"
                required
              />
            </div>
            <div className="text-center">
              <button
                disabled={!isEmailValid}
                onClick={onSubmit}
                type="submit"
                style={{ width: '120px' }}
                className="btn btn-dark btn--c2a py-3 px-4 mb-2 mt-5 text-uppercasae"
              >
                {isProcessing ? <LoadingIndicator /> : <FormattedMessage defaultMessage="submit" />}
              </button>
            </div>
          </>
        )}
        {isSuccessful && (
          <SuccessAlert className="mb-0">
            <FormattedMessage defaultMessage="We have sent you an Email. Please check your inbox." />
          </SuccessAlert>
        )}
        {resetError !== '' && <ErrorAlert>{resetError}</ErrorAlert>}
      </div>
    </BasicModal>
  );
}

ResetPasswordModal.propTypes = {
  authApi: PropTyeps.instanceOf(AuthApi).isRequired,
  hash: PropTyeps.string.isRequired,
};

export default ResetPasswordModal;
