const localeToCountry = {
  ja: 'JP',
  en: 'US',
};

const currencySignByCountry = {
  US: '$',
  JP: '¥',
};

const currencyCodeByCountry = {
  US: 'USD',
  JP: 'JPY',
};

const shoppingCreditRewardByCountry = {
  US: { perStep: 1, total: 20 },
  JP: { perStep: 100, total: 2000 },
};

export default function loadLocaleFromGlobalSettings() {
  const country = localeToCountry[window.MATERIAL_WORLD.i18n.locale];
  return {
    locale: window.MATERIAL_WORLD.i18n.locale,
    messages: window.MATERIAL_WORLD.i18n.strings,
    country,
    currencyCode: currencyCodeByCountry[country],
    currency: currencySignByCountry[country],
    onboardingShoppingCreditReward: shoppingCreditRewardByCountry[country],
  };
}
