import Axios from 'axios';
import { decamelizeKeys } from 'humps';

class AuthApi {
  constructor() {
    this.headers = {
      'Content-Type': 'application/json; charset=utf-8',
    };

    this.api = Axios.create({
      baseURL: '/api/auth',
      headers: this.headers,
      withCredentials: true,
      xsrfCookieName: 'csrftoken',
      xsrfHeaderName: 'X-CSRFTOKEN',
    });

    this.signUp = this.signUp.bind(this);
    this.setNewPassword = this.setNewPassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  async resetPassword(payload) {
    const { data } = await this.api.post('/reset-password/', payload);
    return data;
  }

  async setNewPassword(payload) {
    const { data } = await this.api.post('/set-password/', payload);
    return data;
  }

  async signUp(payload) {
    const { data } = await this.api.post('/signup/', decamelizeKeys(payload));
    return data;
  }
}

export default AuthApi;
