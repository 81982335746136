import React from 'react';

const CloseIcon = () => (
  <svg width="12" height="12" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0.646447" y1="20.6463" x2="20.6463" y2="0.646444" stroke="black" />
    <line x1="20.6464" y1="21.3534" x2="0.646564" y2="1.35355" stroke="black" />
  </svg>
);

function ModalHeader({ onClose }) {
  return (
    <button
      type="button"
      className="btn btn-link position-absolute text-dark outline-0"
      onClick={onClose}
      data-dismiss="modal"
      aria-label="Close"
      style={{ zIndex: 1, right: 0, top: 0 }}
    >
      <CloseIcon />
    </button>
  );
}

export default ModalHeader;
