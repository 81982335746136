import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function LoadingIndicator(props) {
  const { className, text, spinnerSize } = props;
  return (
    <div className={className}>
      {text && <span className="mr-1">{text}</span>}
      <FontAwesomeIcon size={spinnerSize} icon={faSpinner} spin />
    </div>
  );
}

LoadingIndicator.propTypes = {
  className: PropTypes.string,
  text: PropTypes.node,
  spinnerSize: PropTypes.string,
};

LoadingIndicator.defaultProps = {
  className: '',
  text: '',
  spinnerSize: '1x',
};
