import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export function ErrorAlert({ children, className }) {
  return (
    <div className={cx('alert border-primary-red text-white bg-primary-red font-light', className)}>
      {children}
    </div>
  );
}

ErrorAlert.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ErrorAlert.defaultProps = { className: '' };

export function InfoAlert({ children, className }) {
  return (
    <div className={cx('alert bg-light-beige border-light-blue font-light', className)}>
      {children}
    </div>
  );
}

InfoAlert.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

InfoAlert.defaultProps = { className: '' };

export function SuccessAlert({ children, className }) {
  return (
    <div
      className={cx('alert border-lime-green bg-light-green font-light text-black-50', className)}
    >
      {children}
    </div>
  );
}

SuccessAlert.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SuccessAlert.defaultProps = { className: '' };
