import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ResetPasswordModal from './ResetPasswordModal';
import AuthApi from '../../../api-clients/AuthApi';
import loadLocaleFromGlobalSettings from '../../utils/loadLocaleFromGlobalSettings';

library.add(faChevronLeft, faChevronRight);

const authApi = new AuthApi();

const { messages, locale } = loadLocaleFromGlobalSettings();

ReactDOM.render(
  <IntlProvider messages={messages} locale={locale} defaultLocale="en">
    <Router basename={window.location.pathname}>
      <ResetPasswordModal hash="#reset-password" authApi={authApi} />
    </Router>
  </IntlProvider>,
  document.getElementById('shared-root'),
);
