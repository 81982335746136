import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import cx from 'classnames';
import ModalHeader from './ModalHeader';

const customStyles = {
  overlay: {
    zIndex: 99,
    overflowX: 'hidden',
    overflowY: 'auto',
    bacgrkoundColor: 'rgba(95,95,95,0.75)',
  },
};

function BasicModal({ isOpen, onClose, children, modalKey, dialogClassName, modalBodyClass }) {
  return (
    <Modal
      key={modalKey}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onClose}
      contentLabel={modalKey}
      className={cx('modal-dialog outline-0', dialogClassName)}
      style={customStyles}
    >
      <div className="modal-content rounded-0 shadow-sm p-0">
        <ModalHeader onClose={onClose} />
        <div className={cx('modal-body', modalBodyClass)}>{children}</div>
      </div>
    </Modal>
  );
}

BasicModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  modalKey: PropTypes.string.isRequired,
  dialogClassName: PropTypes.string,
  modalBodyClass: PropTypes.string,
};

BasicModal.defaultProps = {
  isOpen: false,
  dialogClassName: '',
  modalBodyClass: '',
};

export default BasicModal;
